<template>
    <div class="contactus_header_container">

        <div class="header_content">

            <div class="header_content1 header_item" @click="goToOtherPage(0)">
                <div>
                    <img :src="require('@/assets/images/phone.png')" alt="">
                    <h3>Contact us</h3>
                    <p>Get in touch with a representative</p>
                </div>
            </div>

            <div class='header_content1 header_item' @click="goToOtherPage(1)">
                <div>
                    <img :src="require('@/assets/images/personalInformation.png')" alt="" class="header_content11">
                    <h3>Press resource</h3>
                    <p>News,company info,and media resource</p>
                </div>
            </div>

            <div class='header_content1 header_item' @click="goToOtherPage(2)">
                <div>
                    <img :src="require('@/assets/images/help.png')" alt="">
                    <h3>Help & support</h3>
                    <p>Get answers to all of your questions</p>
                </div>
            </div>
        </div>
    </div>
</template>

<script>
export default {
    data() {
        return {}
    },
    methods: {
        goToOtherPage(index) {
            this.$emit('indexChange', index)
        }
    }
}
</script>

<style scoped lang="scss">
.contactus_header_container {
    width: 76%;
    margin: 0 auto;
    margin-top: 150px;
    height: 313px;

    .header_content {
        margin-top: 30px;
        line-height: 26px;
        display: flex;
        justify-content: space-between;
    }

    .header_content1 {
        flex: 1;
        margin-right: 30px;
        border-radius: 12px;
        height: 222px;
        display: flex;
        background-color: #074463;
        justify-content: center;
        align-items: center;
    }

    .header_item {
        display: flex;
        flex-direction: column;
        text-decoration: none;
        text-align: center;
        justify-content: center;
        align-items: center;
        color: white;
        transition: all .3s;
    }

    .header_item:hover {
        cursor: pointer;
    }

    .header_content1 img {
        width: 60px;
        height: 60px;
        padding: 10px;
        z-index: 9;
        margin-bottom: 15px;
        border-radius: 50%;
        transition: all .3s;
    }

    .header_content1:hover {
        background-color: #fff;
        color: black;
        box-shadow: 0 0 100px 0 rgba(0, 0, 0, .3)
    }

    .header_content1:hover img {
        border-radius: 50px;
        background-color: #1da6b8;
    }
}

@media (max-width: 768px) {
    .contactus_header_container {
        margin-top: 74px;
        height: auto;
        width: 100%;

        .header_content {
            align-items: center;

            .header_item {
                padding-top: 10px;
                margin-right: 0;
                flex: 0 0 31%;
                height: auto;
                padding: 10px 0;
                margin-bottom: 10px;

                img {
                    width: 30px;
                    height: 30px;
                    margin-bottom: 0;
                }

                h3 {
                    font-size: 14px;
                }

                p {
                    display: none;
                }
            }
        }
    }
}
</style>