<template>
	<div class="page_support">
		<div class="support_title">
			FAQ
		</div>
		<section class="container-1200 layui-fluid" style="min-height: 555px;">
			<CollapseList :collapseList="fqaList" :customClass="customClass"></CollapseList>
		</section>
	</div>
</template>

<script>
import { fqaList } from "./data"
import CollapseList from "@/components/collapseList/index.vue"
import HeaderVue from "./components/header.vue"

export default {
	name: "contactus",
	components: {
		CollapseList,
		HeaderVue
	},
	data() {
		return {
			fqaList,
			customClass: 'support_collapse'
		}
	}
}
</script>

<style lang="scss">
.support_collapse {
	.el-collapse-item__header {
		font-weight: 700;
		font-size: 16px;
		height: 42px;
		line-height: 42px;
		padding: 8px 35px;
		color: #333;
		background-color: #fafafa;
		margin-top: 20px;
	}

	.el-collapse-item__content {
		padding: 10px 40px;
		line-height: 1.6;
		color: #5f5f5f;
	}
}

.page_support {
	.support_title {
		text-align: center;
		font-size: 30px;
		margin-top: 60px;
		margin-bottom: 50px;
		font-weight: bold;
	}

	.header_content {
		margin-top: 30px;
		line-height: 26px;
		display: flex;
		justify-content: space-between;
	}

	.header_content1 {
		flex: 1;
		margin-right: 30px;
		border-radius: 20px;
		height: 300px;
		display: flex;
		background-color: #074463;
		justify-content: center;
		align-items: center;
	}

	.header_content1 img {
		width: 60px;
		height: 60px;
		margin-bottom: 15px;
	}


	.header_content1:hover {
		background-color: #fff;
		color: black;
		box-shadow: 0 0 100px 0 rgba(0, 0, 0, .3)
	}

	.header_content1:hover img {
		border-radius: 50px;
		background-color: #1F79A8;
	}
}


@media (max-width: 768px) {
  .page_support{
	.el-collapse-item__header{
		line-height: 1.3;
	}
  }
}
</style>