<template>
	<div class="contactus_box">
		<section class="header-bg" style="min-height: 255px;">
			<div class="container-1200" style="padding-top: 40px;">
				<HeaderVue @indexChange="onIndexChange"></HeaderVue>
			</div>
		</section>
		<section class="container-1200 layui-fluid">
			<div class="layui-row" v-if="activeIndex == 0">
				<div class="contactus_left">
					<div class="left_title">Headquarter</div>
					<div class="left_content">
						<div>
							<p>B-7-28 Horizon Suites Blok B,</p>
							<p>Kota Warisan 43900 Sepang,</p>
							Selangor,
						</div>
						<div>Malaysia</div>
					</div>
					<div class="left_email">
						Email: <a href="mailto:contact@contrimetric.com?subject=&body=">contact@contrimetric.com</a>
					</div>
				</div>

				<div class="layui-col-md6" style="margin-top: 80px;">
					<img :src="require('@/assets/images/contact.jpg')" style="width: 100%;" />
				</div>
			</div>

			<PressResourcesVue v-if="activeIndex == 1"></PressResourcesVue>
			<SupportVue v-if="activeIndex == 2"></SupportVue>
		</section>
	</div>
</template>

<script>
import HeaderVue from "./components/header.vue"
import PressResourcesVue from "./pressResources.vue";
import SupportVue from "./support.vue";

export default {
	name: "contactus",
	components: {
		HeaderVue,
		PressResourcesVue,
		SupportVue
	},
	data() {
		return {
			activeIndex: 0
		}
	},
	methods: {
		onIndexChange(index) {
			this.activeIndex = index
		}
	}
}
</script>

<style scoped lang="scss">
.contactus_box {
	.layui-row {
		display: flex;
		justify-content: space-between;
		align-items: center;
	}

	.contactus_left {
		font-family: none;
	}

	.contactus_left .left_title {
		font-size: 38px;
		margin-bottom: 50px;
	}

	.contactus_left .left_content,
	.contactus_left .left_email {
		color: #606060;
		font-size: 18px;
	}

	.contactus_left .left_content {
		line-height: 28px;
	}

	.contactus_left .left_email {
		display: flex;
		margin-top: 24px;
	}

	.contactus_left .left_email a {
		color: black;
		margin-left: 10px;
		text-decoration: underline;
	}

	.header_content {
		margin-top: 30px;
		line-height: 26px;
		display: flex;
		justify-content: space-between;
	}

	.header_content1 {
		flex: 1;
		margin-right: 30px;
		border-radius: 20px;
		height: 300px;
		display: flex;
		background-color: #074463;
		justify-content: center;
		align-items: center;
	}

	a {
		display: flex;
		flex-direction: column;
		text-decoration: none;
		text-align: center;
		justify-content: center;
		align-items: center;
		color: white;
	}

	.header_content1 img {
		width: 60px;
		height: 60px;
		margin-bottom: 15px;
	}


	.header_content1:hover {
		background-color: #fff;
		color: black;
		box-shadow: 0 0 100px 0 rgba(0, 0, 0, .3)
	}

	.header_content1:hover img {
		border-radius: 50px;
		background-color: #1F79A8;
	}
}

@media (max-width: 768px) {
	.contactus_box {
		.layui-row {
			margin-top: 20px;

			.left_title {
				font-size: 26px;
				margin-bottom: 20px;
			}

			.left_content{
				font-size: 16px;
			}

			.layui-col-md6{
				display: none;
			}
		}
	}
}
</style>